<template>
  <div class="columns is-mobile is-vcentered is-variable is-1 bottom-border banner-container"
       :class="smallClass">
    <div class="column is-narrow is-paddingless">
      <profile-picture
        @click.native="openUserInfo()"
        :picture="userAvatar"
        :initials="initials"
        class="profile-picture"
        :class="smallClass"
      ></profile-picture>
    </div>
    <div class="column is-paddingless pl-1">
      <div class="user-name" :class="smallClass">
        {{ userName }}
      </div>
    </div>
    <div v-if="!isDeletedAccount" class="column is-narrow is-paddingless">
      <div class="buttons-container">
<!--        Jitsi has been removed-->
<!--        <video-call-icon @click="openJitsiCall()" class="has-fill-primary video-call-button mr-2"></video-call-icon>-->
        <info-icon @click="openUserInfo()" class="info-icon-color mr-1"></info-icon>
      </div>
    </div>
  </div>
</template>

<script>
import ProfilePicture from "@/shared/components/common/profile/ProfilePicture";
import InfoIcon from "@/assets/icons/info.svg";
import { fullName, initials } from "@/shared/utils";
import { Constants } from "@/web/constants";
import UserProfileModal from "@/web/components/profile/UserProfileModal";
// import VideoCallIcon from "@/assets/icons/video_cam_small.svg";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "InboxThreadBanner",

  components: {
    ProfilePicture,
    InfoIcon,
    // VideoCallIcon
  },

  props: {
    user: {
      type: Object,
    },

    isSmall: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters("currentUser", { currentUserId: "uuid" }),

    Constants: () => Constants,

    userAvatar() {
      return this.user.picture;
    },

    initials() {
      return initials(this.user);
    },

    userName() {
      if (this.isDeletedAccount) {
        return this.$t("inbox.inbox_deleted_user");
      } else {
        return fullName(this.user);
      }
    },

    isDeletedAccount() {
      return !this.user.user_identity_token;
    },

    smallClass() {
      if (this.isSmall) {
        return "is-small";
      } else {
        return "";
      }
    },
  },

  methods: {
    openUserInfo() {
      if (!this.isDeletedAccount) {
        this.$buefy.modal.open({
          parent: this,
          component: UserProfileModal,
          hasModalCard: true,
          canCancel: true,
          trapFocus: true,
          props: {
            userId: this.user.user_identity_token,
          },
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.banner-container {
  padding: 10px;
  background-color: var(--inbox-thread-banner-bg-color);
  margin-left: var(--inbox-thread-banner-x-margin);
  margin-right: var(--inbox-thread-banner-x-margin);
  margin-top: 0 !important;
  margin-bottom: 0!important;
  border-radius: var(--inbox-thread-banner-border-radius);

  &.is-small {
    //padding: 9px;
  }
}

.bottom-border {
  border-bottom: 1px solid var(--inbox-thread-banner-separator-color);
}

.info-icon-color {
  fill: var(--inbox-thread-banner-item-color);
  cursor: pointer;
}

.video-call-button {
  cursor: pointer;
  fill: var(--inbox-thread-banner-item-secondary-color);
}

.profile-picture {
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 16px;

  &.is-small {
    width: 30px;
    height: 30px;
    font-size: 12px;
  }
}

.buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: auto;
}

.info-icon-color {
  fill: #828282;
  cursor: pointer;
}

.info-button {
  cursor: pointer;
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-call-button {
  cursor: pointer;
  width: 30px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-name {
  font-style: normal;
  font-weight: var(--global-title-text-weight);
  font-size: 18px;
  line-height: 25px;
  color: var(--text-color-primary);

  &.is-small {
    font-size: 14px;
    line-height: 20px;
  }
}
</style>
